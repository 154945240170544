<template>
  <div>
    <div class="d-flex align-items-center mb-2">
      <a @click="$router.go(-1)" class="grey-color">
        <div class="d-flex align-items-center mr-2">
          <feather-icon icon="ArrowLeftIcon" size="20" class="mr-25" />
          <h6 class="mb-0">Назад</h6>
        </div>
      </a>
    </div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="getMerchant === undefined">
      <h4 class="alert-heading">Ошибка при получении данных этого мерчанта</h4>
      <div class="alert-body">
        Мерчанта с таким айди не существует. Проверьте
        <b-link class="alert-link" :to="{ name: 'merchants' }">
          Список мерчантов
        </b-link>
        для других мерчантов.
      </div>
    </b-alert>

    <template v-if="getMerchant">
      <!-- First Row -->
      <b-row>
        <b-col cols="12"md="12">
          <MerchantViewInfoCard />
        </b-col>
        
      </b-row>
      <b-row>
        <b-col cols="12" md="3">
          <MerchantViewDescCard />
        </b-col>
        <b-col>
          <MerchantViewProducts :offerList="getMerchant.offerList"/>
        </b-col>
        <b-col>
          <MerchantViewOffers :offerList="getMerchant.offerList" class="res-mt-15"/>
          <!-- <MerchantViewExpired class="mt-2"/> -->
        </b-col>
      </b-row>

      <b-row>
        
      </b-row>

    </template>
  </div>
</template>

<script>
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";
import { mapGetters } from 'vuex';
import MerchantViewInfoCard from './MerchantViewInfoCard.vue';
import MerchantViewDescCard from './MerchantViewDescCard.vue';
import MerchantViewOffers from './MerchantViewOffers.vue';
import MerchantViewProducts from './MerchantViewProducts.vue';
import MerchantViewExpired from './MerchantViewExpired.vue';

export default {
  components: {
    MerchantViewExpired,
    MerchantViewProducts,
    MerchantViewOffers,
    MerchantViewDescCard,
    BRow,
    BCol,
    BAlert,
    BLink,
    MerchantViewInfoCard,
  },
  computed: {
    ...mapGetters([
      'getMerchant',
    ])
  },
  data() {
    return {};
  },
  mounted () {
    this.$store.dispatch('fetchMerchant', this.$router.currentRoute.params.id)
  },
  methods: {
  },
};
</script>

<style></style>
