<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        md="4"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            v-if="getMerchant.logoUrl && getMerchant.logoUrl.length > 0"
            :src="getMerchant.logoUrl"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <b-avatar
            v-else
            :variant="`light-primary`"
            :text="avatarText(getMerchant.name)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="">
                {{ getMerchant.name }}
              </h4>
              <b-badge :variant="statusVariant(getMerchant.vendorState).color">
                {{ statusVariant(getMerchant.vendorState).text }}
              </b-badge>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{
                  name: 'merchant-edit',
                  params: { id: this.$router.currentRoute.params.id },
                }"
                variant="primary"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              >
                Изменить
              </b-button>
              <b-button
                variant="outline-danger"
                @click.prevent="deleteMerchant"
                class="ml-1"
              >
                Удалить
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" md="4">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">ID:</span>
            </th>
            <td class="pb-50">
              {{ getMerchant.id }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Название:</span>
            </th>
            <td class="pb-50">
              {{ getMerchant.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Категории:</span>
            </th>
            <td class="pb-50">
              <span
                v-for="item in getMerchant.vendorCategoryList"
                :key="item.id"
              >
                {{ item.titleRu }}.
              </span>
              <span v-if="getMerchant.vendorCategoryList == ''">
                Нет категории
              </span>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Статус:</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge :variant="statusVariant(getMerchant.vendorState).color">
                {{ statusVariant(getMerchant.vendorState).text }}
              </b-badge>
            </td>
          </tr>
        </table>
      </b-col>

      <b-col cols="12" md="4">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Тег:</span>
            </th>
            <td class="pb-50">
              {{ getMerchant.businessType }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Дата создания:</span>
            </th>
            <td class="pb-50">
              {{ getMerchant.dateCreated | formatDate }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Дата редактирования:</span>
            </th>
            <td class="pb-50">
              {{ getMerchant.dateModified | formatDate }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol, BBadge } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { avatarText } from "@core/utils/filter";
import { mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios.js";

export default {
  components: {
    BBadge,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters(["getMerchant"]),
  },
  setup() {
    return {
      avatarText,
    };
  },
  data() {
    return {};
  },
  methods: {
    statusVariant(status) {
      if (status === "ACTIVE")
        return {
          color: "light-success",
          text: "Активный",
        };
      if (status === "DELETED")
        return {
          color: "light-danger",
          text: "Удаленный",
        };
      if (status === "BLOCKED")
        return {
          color: "light-warning",
          text: "Заблокированный",
        };
      if (status === "SUSPENDED")
        return {
          color: "light-secondary",
          text: "Приостановленный",
        };
      return {
        color: "light-primary",
        text: "Непонятно",
      };
    },
    async deleteMerchant() {
      this.$bvModal
        .msgBoxConfirm("Вы уверены что хотите удалить?", {
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          okTitle: "Удалить",
          cancelTitle: "Отмена",
        })
        .then((value) => {
          const selectedMerchant = [];
          selectedMerchant.push(this.$router.currentRoute.params.id);
          if (value === true) {
            axiosIns
              .post("vendor/change-state/", {
                vendorIdList: selectedMerchant,
                vendorState: "DELETED",
              })
              .then((response) => {
                if (response.status == 200) {
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: `Успешно`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: `Мерчант был успешно удален!`,
                      },
                    },
                    {
                      position: "top-right",
                    }
                  );
                  this.getData();
                }
              })
              .catch((error) => {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Ошибка`,
                      icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                      variant: "danger",
                      text: error.response.data.errorMessage,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
                if (error.response) {
                  // Request made and server responded
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log("Error", error.message);
                }
              })
              .finally(() => {
                this.$router.push({ name: "merchants" });
              });
          }
        });
    },
  },
};
</script>

<style></style>
