<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="px-2 py-1">
        <b-row>
          <b-col md="12">
            <div
              class="
                d-flex
                res-flex-column res-align-items-start
                justify-content-between
              "
            >
              <div>
                <h4 class="mb-0" v-if="totalCount">
                  Товары ({{ totalCount }})
                </h4>
                <h4 class="mb-0" v-else>Товары</h4>
              </div>
              <div
                class="
                  d-flex
                  res-flex-column res-users-list-search
                  align-items-center
                  res-mt-2
                "
              >
                <div class="res-m-0 res-w-100">
                  <!-- basic search -->
                  <!-- <b-input-group class="input-group-merge res-w-100 res-mt-2">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                    size="sm"
                      class="res-w-100"
                      @input="handleSearch"
                      v-model="searchTerm"
                      placeholder="Поиск"
                    />
                  </b-input-group> -->
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <vue-good-table
        v-if="offerList.length"
        class="position-relative custom-scroll"
        :columns="columns"
        :rows="offerList"
        :rtl="false"
        :select-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        @on-sort-change="onSortChange"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'id'" class="text-nowrap">
            <span class="text-nowrap text-nowrap">{{ props.row.id }}</span>
          </span>

          <!-- Column: Name -->
          <span
            v-else-if="props.column.field === 'titleRu'"
            class="text-nowrap"
          >
            <span class="text-nowrap font-weight-bold text-nowrap">
              {{ props.row.titleRu }}
            </span>
          </span>

          <!-- Column: daysCountTillTheEnd -->
          <span
            v-else-if="props.column.field === 'daysCountTillTheEnd'"
            class="text-nowrap"
          >
            <span class="text-nowrap text-nowrap">
              {{ props.row.daysCountTillTheEnd }} дней
            </span>
          </span>

          <!-- Column: dateCreated -->
          <span
            v-else-if="props.column.field === 'dateCreated'"
            class="text-nowrap"
          >
            <span>
              {{ props.formattedRow.dateCreated | formatDate }}
            </span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'vendorState'">
            <b-badge :variant="statusVariant(props.row.vendorState).color">
              {{ statusVariant(props.row.vendorState).text }}
            </b-badge>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between px-2 pb-2 pt-1 flex-wrap">
            <div class="d-flex align-items-center">
              <v-select
                v-model="pageLength"
                :options="pages"
                :clearable="false"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
                class="per-page-selector d-inline-block mx-50 select-size-sm"
              />
            </div>

            <!-- pagination -->
            <div>
              <b-pagination
                :value="1"
                :total-rows="offerList.length"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>

        <template slot="emptystate">
          <div class="text-center">
            <span> Нет данных </span>
          </div>
        </template>
      </vue-good-table>
      <div v-else class="px-2 pb-2">
        <span> Пусто </span>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BButton,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import axiosIns from "@/libs/axios.js";

export default {
  components: {
    ToastificationContent,
    Ripple,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
  },
  props: {
    offerList: {
      type: Array,
      default: () => [],
    },
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters(["getMerchant"]),
    statusVariant() {
      const statusVariants = {
        /* eslint-disable key-spacing */
        ACTIVE: {
          color: "light-success",
          text: "Активный",
        },
        DELETED: {
          color: "light-danger",
          text: "Удаленный",
        },
        BLOCKED: {
          color: "light-warning",
          text: "Заблокированный",
        },
        SUSPENDED: {
          color: "light-secondary",
          text: "Приостановленный",
        },
        /* eslint-enable key-spacing */
      };

      return (status) => statusVariants[status];
    },
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      isAddNewUserModalActive: false,
      pageLength: 5,
      currentPage: 1,
      totalCount: null,
      sortField: null,
      field: null,
      fieldValue: null,
      pages: ["3", "5", "10"],
      columns: [
        {
          label: "ID",
          field: "id",
          type: "number",
        },
        {
          label: "Название",
          field: "titleRu",
          type: "text",
        },
        {
          label: "Скидка",
          field: "discount",
          type: "number",
        },
        {
          label: "Осталось",
          field: "daysCountTillTheEnd",
          type: "number",
        },
        {
          label: "Тип",
          field: "offerType",
          type: "text",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  mounted() {},
  methods: {
    async getData() {
      const response = await axiosIns
        .get(`vendor/all`, {
          params: {
            page: this.currentPage,
            size: this.pageLength,
            sort: this.sortField,
            field: this.field,
            fieldValue: this.fieldValue,
          },
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
      this.rows = response.data.data.vendors;
      this.totalCount = response.data.data.totalCount;
    },
    handleSearch(searching) {
      this.field = "name";
      this.fieldValue = searching;
      this.getData();
    },
    async handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    async handlePageLengthChange(active) {
      this.pageLength = active;
      this.getData();
    },
    onSortChange(params) {},
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.per-page-selector {
  min-width: 70px;
  max-width: 70px;
}
</style>
